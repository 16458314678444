import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbOffcanvas, NgbNavModule ,NgbProgressbarModule,NgbCollapseModule,NgbAccordionModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ui',
  templateUrl: './ui.component.html',
  styleUrls: ['./ui.component.scss']
})

/**
 * Ui Component
 */
export class UiComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  isCollapsed = false;
  isCollapsed1 = false;
  
  constructor(private modalService: NgbModal, private offcanvasService: NgbOffcanvas) { }

  ngOnInit(): void {
    document.getElementById('mainContent')?.classList.add('overflow-visible');
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'UI Elements' },
      { label: 'UI Elements', active: true }
    ];
  }

  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    this.modalService.open(content, { windowClass: 'modal-holder' });
  }

  scrollTo(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /**
   * Open extra large modal
   * @param exlargeModal extra large modal data
   */
  extraLarge(exlargeModal: any) {
    this.modalService.open(exlargeModal, { size: 'xl', windowClass: 'modal-holder', centered: true });
  }

  /**
   * Open Large modal
   * @param largeDataModal large modal data
   */
  largeModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'lg', windowClass: 'modal-holder', centered: true });
  }

  /**
   * Open small modal
   * @param smallDataModal small modal data
   */
  smallModal(smallDataModal: any) {
    this.modalService.open(smallDataModal, { size: 'sm', windowClass: 'modal-holder', centered: true });
  }

  /**
   * Open small modal
   * @param smallDataModal small modal data
   */
  fullModal(smallDataModal: any) {
    this.modalService.open(smallDataModal, { size: 'fullscreen', windowClass: 'modal-holder' });
  }

  /**
   * Open center modal
   * @param centerDataModal center modal data
   */
  centerModal(centerDataModal: any) {
    this.modalService.open(centerDataModal, { centered: true, windowClass: 'modal-holder' });
  }

  /**
   * Open scroll modal
   * @param scrollDataModal scroll modal data
   */
  scrollModal(scrollDataModal: any) {
    this.modalService.open(scrollDataModal, { scrollable: true });
  }

  /**
   * Open scroll modal
   * @param staticDataModal scroll modal data
   */
  staticModal(staticDataModal: any) {
    this.modalService.open(staticDataModal, { backdrop: 'static', keyboard: false, centered: true, windowClass: 'modal-holder' });
  }

  // Offcanvas
  // Demo Offcanvas
  openLeft(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'start' });
  }

  // Offcanvas Open Top
  openTop(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'top' });
  }

  // Offcanvas Open Right
  openRight(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  // Offcanvas Open Bottom
  openBottom(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'bottom' });
  }

  // Offcanvas Open No Backdrop
  openNoBackdrop(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { backdrop: false });
  }

  // Offcanvas Open Scroll
  openScroll(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { scroll: true });
  }

  // Offcanvas Open No Keyboard
  openNoKeyboard(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { keyboard: false });
  }

}
